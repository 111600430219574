// import React, { useState } from 'react';
// import './Login.css';

// const Login = () => {
//   const [email, setEmail] = useState('');
//   const [password, setPassword] = useState('');
//   const [errorMessage, setErrorMessage] = useState('');

//   // Handle form submission
//   const handleSubmit = (e) => {
//     e.preventDefault(); // Prevent page refresh
//     setErrorMessage(''); // Clear error message before validation

//     // Simple validation
//     if (!email || !password) {
//       setErrorMessage('Please fill in both fields.');
//       return;
//     }

//     // Add your login logic here (e.g., API call to authenticate user)
//     console.log('Logging in with:', email, password);

//     // Example of resetting the form after successful login
//     setEmail('');
//     setPassword('');
//   };

//   return (
//     <div className="login-container">
//       <form className="login-form" onSubmit={handleSubmit}>
//         <h2>Login</h2>

//         {errorMessage && <p className="error-message">{errorMessage}</p>}

//         <div className="form-group">
//           <label htmlFor="email">Email:</label>
//           <input
//             type="email"
//             id="email"
//             value={email}
//             onChange={(e) => setEmail(e.target.value)}
//             required
//           />
//         </div>

//         <div className="form-group">
//           <label htmlFor="password">Password:</label>
//           <input
//             type="password"
//             id="password"
//             value={password}
//             onChange={(e) => setPassword(e.target.value)}
//             required
//           />
//         </div>

//         <button type="submit" className="login-button">Login</button>
//       </form>
//     </div>
//   );
// };

// export default Login;






import React, { useState } from 'react';
import { Link,navigate, useNavigate } from 'react-router-dom'; // Assuming you're using React Router for navigation
import axios from 'axios';
import './Login.css';

const Login = () => {

  const navigate=useNavigate();

  const [email, setEmail] = useState('');
  console.log(email);
  const [password, setPassword] = useState('');
  console.log(password);
  const [errorMessage, setErrorMessage] = useState(false);
  const [isemailvalid, setIsemailvalid] = useState('');
  const [ispasswordvalid, setIspasswordvalid] = useState('');


  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);

    if (value !== "") {
      setErrorMessage("");
      setIsemailvalid(false);
      return false;
    } 
   
  };



  const handlePasswordChange = (e) => {
    const value = e.target.value;
    setPassword(value);

    if (value !== "") {
      setErrorMessage("");
      setIspasswordvalid(false);
    }
  };

  const validatesignin=()=>{
    if(email==="")
    {
      setErrorMessage("Email can't be empty");
      setIsemailvalid(true);
      return false;
    }
    else if(password==="")
    {
      setErrorMessage("password can't be empty");
      setIspasswordvalid(true);
      return false;
    }

    return true;
  }

  // Handle form submission
  const handleSubmit =(e) => {
    e.preventDefault();
    if (validatesignin()) {
     
        axios.post("http://98.130.35.113:8000/api/login/", { "email":email, "password":password })
        .then((response) => {
          console.log("login successful", response.data);
          localStorage.setItem("token", response.data.token); // Save token
          navigate("/videos"); // Redirect after login
        })
        .catch (error=> {
          setErrorMessage(error.response?.data?.detail || "Login failed. Please check your credentials."); // Handle errors
        })
    }
}

             
              
              


    
    
  
    
  

  return (
    <div className="login-container">
      <form className="login-form" onSubmit={handleSubmit}>
        <h2>Login</h2>

        {errorMessage && <p className="error-message">{errorMessage}</p>}

        <div className="form-group">
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={handleEmailChange}
            style={{borderColor:isemailvalid?"red":""}}
          />
         

        </div>

        <div className="form-group">
          <label htmlFor="password">Password:</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={handlePasswordChange}
            style={{borderColor:ispasswordvalid?"red":""}}
          />
        
        </div>

        <button type="submit" className="login-button">Login</button>

        <div className="bottom-links">
          <Link to="/forgot-password" className="forgot-password">
            Forgot Password?
          </Link>
          <span> | </span>
          <Link to="/signup" className="signup-link">
            Sign Up
          </Link>
        </div>
      </form>
    </div>
  );
};

export default Login;

