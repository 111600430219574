import React from "react";
import './Videos.css';


const Videos=()=>{
    return(
        <div>
            <h1>Hi this is videos component</h1>
        </div>
    )
}

export default Videos;